@import url('https://fonts.googleapis.com/css2?family=Whisper&display=swap');

.App {
  text-align: center;
  overflow: hidden;
}


@media (min-width: 768px) {

}