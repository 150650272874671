.Home .app-header {
  position: relative;
  height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -100;
}

.Home .app-header .banner-video {
  height: 100vh;
  z-index: 0;
  margin: auto;
}

@media (max-width: 1750px) {
  .Home .app-header .fade blockquote {
      font-size: 50px !important;
    }
  
  .Home .app-header .fade blockquote p {
    font-size: 30px !important;
  }
}

@media (max-width: 1100px) {
  .Home .app-header .fade blockquote {
    font-size: 30px !important;
  }

  .Home .app-header .fade blockquote p {
    font-size: 16px !important;
  }
}


.Home .app-header .fade {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

}

.Home .app-header .fade blockquote {
  font-family: "Whisper", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 80px;
  width: 70%;
}

.Home .app-header .fade blockquote p {
  padding: 0;
  margin: 0;
  margin-top: 20px;
  font-size: 40px;
  font-family: sans-serif;
}

/* @media (max-width: 768px) {
  .Home .app-header {
    height: 200px;
  }

  .Home .app-header .banner-video {
    height: 200px;
  }

  .Home .app-header .fade blockquote {
    font-size: 22px;
    width: 90%;
  }

  .Home .app-header .fade blockquote p {
    font-size: 16px;
  }
} */
